import * as React from 'react';
import Span from '../Span/Span';
import { IconExclamationMark } from 'styleguide/icons';
import PageLevelErrorItem from './PageLevelErrorItem';

interface Props {
  message: string;
  children?: React.ReactNode;
}

const PageLevelError = ({ message, children }: Props) => (
  <div className="relative bg-red-700 text-white mb-4 rounded-lg py-4 pl-10 pr-3">
    <IconExclamationMark color="white" size="lg" className="absolute left-3" />
    <Span className="font-hvMedium">{message}</Span>
    <ul>{children}</ul>
  </div>
);

PageLevelError.Item = PageLevelErrorItem;

export default PageLevelError;
