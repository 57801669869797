import * as React from 'react';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';

interface Props {
  children?: React.ReactNode;
  value?: string;
}

const PageLevelErrorItem = ({ children, value }: Props) => (
  <li>
    <HtmlContent content={value} className="prose-a:text-white hover:prose-a:text-white" />
    {children}
  </li>
);

export default PageLevelErrorItem;
